import "../ExchangePolicyPage/ExchangePolicyPage.css";
import { useTranslation } from "react-i18next";

export default function UserAgreementPage() {
  const { t } = useTranslation();

  return (
    <div className="exchange-policy-container">
      <h3 className="modal-heading">
        {t("UserAgreementPage.UserAgreementPage")}
      </h3>
      <h4 className="modal-subheading">{t("UserAgreementPage.Regulations")}</h4>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Content")}</h3>

      <ol>
        <li>{t("UserAgreementPage.General")}</li>
        <li>{t("UserAgreementPage.List")}</li>
        <li>{t("UserAgreementPage.Purpose")}</li>
        <li>{t("UserAgreementPage.The_procedure")}</li>
        <li>{t("UserAgreementPage.Location")}</li>
        <li>{t("UserAgreementPage.Terms")}</li>
        <li>{t("UserAgreementPage.Protection")}</li>
        <li>{t("UserAgreementPage.Rights")}</li>
        <li>{t("UserAgreementPage.Procedure")}</li>
        <li>{t("UserAgreementPage.State")}</li>
      </ol>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Generals")}</h3>

      <p>{t("UserAgreementPage.Definition")}</p>

      <p>
        <strong>{t("UserAgreementPage.personal")}</strong>
        {t("UserAgreementPage.named")}
      </p>

      <p>
        <b>{t("UserAgreementPage.responsible")}</b>&nbsp;
        {t("UserAgreementPage.designated")}
      </p>

      <p>
        <b>{t("UserAgreementPage.owner")}</b>&nbsp;
        {t("UserAgreementPage.natural")}
      </p>

      <p>
        <strong>{t("UserAgreementPage.Register")}</strong>
        {t("UserAgreementPage.information")}
      </p>

      <p>
        <b>{t("UserAgreementPage.available")}</b>
        {t("UserAgreementPage.directories")}
      </p>

      <p>
        <b>{t("UserAgreementPage.consent")}</b>&nbsp;
        {t("UserAgreementPage.documented")}
      </p>

      <p>
        <b>{t("UserAgreementPage.depersonalization")}</b>{" "}
        {t("UserAgreementPage.removal")}
      </p>

      <p>
        <b>{t("UserAgreementPage.processing")}</b>{" "}
        {t("UserAgreementPage.action")}
      </p>

      <p>
        <b>{t("UserAgreementPage.data")}</b>
        {t("UserAgreementPage.informations")}
      </p>

      <p>
        <b>{t("UserAgreementPage.manager")}</b>{" "}
        {t("UserAgreementPage.naturals")}
      </p>

      <p>
        <b>{t("UserAgreementPage.subject")}</b>{" "}
        {t("UserAgreementPage.relation")}
      </p>

      <p>
        <b>{t("UserAgreementPage.third")}</b> {t("UserAgreementPage.exception")}
      </p>

      <p>
        <b>{t("UserAgreementPage.special")}</b> {t("UserAgreementPage.racial")}
      </p>

      <p>{t("UserAgreementPage.Regulation")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.List_of")}</h3>

      <p>{t("UserAgreementPage.seller")}</p>

      <ul>
        <li>{t("UserAgreementPage.database_of")}</li>
      </ul>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Purposes")}</h3>

      <p>{t("UserAgreementPage.The_purpose")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.procedure")}</h3>

      <p>{t("UserAgreementPage.consents")}</p>

      <p>{t("UserAgreementPage.subjects")}</p>

      <ul>
        <li>{t("UserAgreementPage.document")}</li>
        <li>{t("UserAgreementPage.electronic_document")}</li>
        <li>{t("UserAgreementPage.mark")}</li>
      </ul>

      <p>{t("UserAgreementPage.The_consent")}</p>

      <p>{t("UserAgreementPage.notification")}</p>

      <p>{t("UserAgreementPage.processings")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Locations")}</h3>

      <p>{t("UserAgreementPage.personal_data")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Terms_of")}</h3>

      <p>{t("UserAgreementPage.The_procedures")}</p>

      <p>{t("UserAgreementPage.Access_to_personal")}</p>

      <p>{t("UserAgreementPage.subject_of")}</p>

      <p>{t("UserAgreementPage.The_request_states")}</p>

      <ul>
        <li>{t("UserAgreementPage.surname")}</li>
        <li>{t("UserAgreementPage.name")}</li>
        <li>{t("UserAgreementPage.Surname")}</li>
        <li>{t("UserAgreementPage.information_about")}</li>
        <li>{t("UserAgreementPage.list_of_requested")}</li>
        <li>{t("UserAgreementPage.the_purpose")}</li>
      </ul>

      <p>{t("UserAgreementPage.The_term")}</p>

      <p>{t("UserAgreementPage.Delaying")}</p>

      <p>{t("UserAgreementPage.The_notice")}</p>

      <p>{t("UserAgreementPage.The_notice_of")}</p>

      <ul>
        <li>{t("UserAgreementPage.surnames")}</li>
        <li>{t("UserAgreementPage.the_date")}</li>
        <li>{t("UserAgreementPage.the_reason")}</li>
        <li>{t("UserAgreementPage.the_period")}</li>
      </ul>

      <p>{t("UserAgreementPage.Denial_of_access")}</p>

      <p>{t("UserAgreementPage.The_rejection")}</p>

      <ul>
        <li>{t("UserAgreementPage.surnamess")}</li>
        <li>{t("UserAgreementPage.the_date_of")}</li>
        <li>{t("UserAgreementPage.reason_for_refusal")}</li>
      </ul>

      <p>{t("UserAgreementPage.The_decision")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Protection_of")}</h3>

      <p>{t("UserAgreementPage.The_owner")}</p>

      <p>{t("UserAgreementPage.The_responsible")}</p>

      <p>{t("UserAgreementPage.The_duties")}</p>

      <p>{t("UserAgreementPage.The_responsible_person")}</p>

      <ul>
        <li>{t("UserAgreementPage.know_the")}</li>
        <li>{t("UserAgreementPage.develop_procedures")}</li>
        <li>{t("UserAgreementPage.ensure")}</li>
        <li>{t("UserAgreementPage.develop_a")}</li>
        <li>{t("UserAgreementPage.notify")}</li>
        <li>{t("UserAgreementPage.to_ensure")}</li>
      </ul>

      <p>{t("UserAgreementPage.In_order_to")}</p>

      <ul>
        <li>{t("UserAgreementPage.receive")}</li>
        <li>{t("UserAgreementPage.make_copies")}</li>
        <li>{t("UserAgreementPage.to_participate")}</li>
        <li>{t("UserAgreementPage.submit_for")}</li>
        <li>{t("UserAgreementPage.to_receive")}</li>
        <li>{t("UserAgreementPage.sign")} </li>
      </ul>

      <p>{t("UserAgreementPage.Employees")}</p>

      <p>{t("UserAgreementPage.Employees_who")}</p>

      <p>{t("UserAgreementPage.Persons")}</p>

      <p>{t("UserAgreementPage.Personal_data")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Rights_of")}</h3>

      <p>{t("UserAgreementPage.The_subject")}</p>

      <ul>
        <li>{t("UserAgreementPage.to_know")}</li>
        <li>{t("UserAgreementPage.to_receives")}</li>
        <li>{t("UserAgreementPage.to_access")}</li>
        <li>{t("UserAgreementPage.to_receive_no")}</li>
        <li>{t("UserAgreementPage.submit_a_reasoned")}</li>
        <li>{t("UserAgreementPage.make_a_reasoned")}</li>
        <li>{t("UserAgreementPage.to_protect_your")}</li>
        <li>{t("UserAgreementPage.apply_for")}</li>
        <li>{t("UserAgreementPage.apply_legal")}</li>
      </ul>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.Procedures")}</h3>

      <p>{t("UserAgreementPage.The_subject_of")}</p>

      <p>{t("UserAgreementPage.Access_of_the")}</p>

      <p>{t("UserAgreementPage.The_subjects")}</p>

      <p>{t("UserAgreementPage.request_states")}</p>

      <ul>
        <li>{t("UserAgreementPage.surnamesss")}</li>
        <li>{t("UserAgreementPage.other_information")}</li>
        <li>{t("UserAgreementPage.information_about_the")}</li>
        <li>{t("UserAgreementPage.list_of_requesteds")}</li>
      </ul>

      <p>{t("UserAgreementPage.The_term_of")}</p>

      <p>{t("UserAgreementPage.The_request")}</p>

      <p>&nbsp;</p>

      <h3>{t("UserAgreementPage.State_registration")}</h3>

      <p>
        {t("UserAgreementPage.State_registrations")}`
        <a href="http://zakon5.rada.gov.ua/laws/show/2297-17">
          {t("UserAgreementPage.On_the_Protection")}
        </a>
        `.
      </p>
    </div>
  );
}
