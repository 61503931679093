import { useRef } from "react";
import {
  useGetMostPopularQuery,
  useGetProductsbyTagsQuery,
} from "../../app/store";
import "./Suggestions.css";

import Slider from "react-slick";
import ProductCard from "../../Components/ProductCard/ProductCard";

import arrowLeft from "../../images/arrow-left.svg";
import arrowRight from "../../images/arrow-right.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";

function Suggestions(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
    ],
  };

  const { data: tagsData } = useGetProductsbyTagsQuery(props.id);

  const { data } = useGetMostPopularQuery();

  const sliderRef = useRef(null);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const { t } = useTranslation();

  return (
    <div className="suggestions">
      <div className="suggestions--top">
        <h3 className="suggestions--title"> {t("Suggestions.Suggestions")} </h3>
        <div className="cards--paginator">
          <button onClick={handlePrevClick}>
            <img src={arrowLeft} alt="<" />
          </button>
          <button onClick={handleNextClick}>
            <img src={arrowRight} alt=">" />
          </button>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings} className="product--cards">
        {tagsData && tagsData.length > 0
          ? tagsData.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                image_url={product.image_url}
                title={product.title}
                grammage={product.grammage}
                price={product.price}
                discount={product.discount}
                discounted_price={product.discounted_price}
                index={product.index}
                bestseller={product.bestseller}
                na_zakaz={product.na_zakaz}
                newdish={product.newdish}
                meal={product.meal}
                vegan={product.vegan}
                is_special_offer={product.is_special_offer}
              />
            ))
          : data &&
            data.results.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                image_url={product.image_url}
                title={product.title}
                grammage={product.grammage}
                price={product.price}
                discount={product.discount}
                discounted_price={product.discounted_price}
                index={product.index}
                bestseller={product.bestseller}
                na_zakaz={product.na_zakaz}
                newdish={product.newdish}
                meal={product.meal}
                vegan={product.vegan}
                is_special_offer={product.is_special_offer}
              />
            ))}
      </Slider>
    </div>
  );
}

export default Suggestions;
