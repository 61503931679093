import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCart } from "../../features/shoppingCart/shoppingCartSlice";

import cart from "../../images/cart.svg";
import blueCart from "../../images/blue-cart.svg";
import logo from "../../images/logo.svg";
import burger from "../../images/burger.svg";
import closeMenu from "../../images/closeMenu.svg";
import { useTranslation } from "react-i18next";
import lupa from "../../images/lupa.svg";

import "./MobileHeader.css";
import Search from "../Search/Search";

function MobileHeader({ setCartVisible }) {
  const [showMenu, setShowMenu] = useState(false);
  const [summaryText, setSummaryText] = useState("УКР");
  const [showSearch, setShowSearch] = useState(false);

  const { t, i18n } = useTranslation();
  const handleItemClick = (text) => {
    setSummaryText(text);
  };

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    saveLanguageToLocalStorage(lng);
    window.location.reload();
  };

  const saveLanguageToLocalStorage = (language) => {
    localStorage.setItem("selectedLanguage", language);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const { amount } = useSelector(selectCart);
  const { accessToken } = useSelector((state) => state.user);

  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  return (
    <>
      {showSearch ? (
        <Search close={handleCloseSearch} />
      ) : (
        <header className="header--mobile">
          <div className="header--mobile--container">
            <div className="cart-search-container">
              <button
                className={amount > 0 ? `cart-icon-filled` : `cart-icon`}
                onClick={() => setCartVisible(true)}
              >
                <span>{amount}</span>
                <img className="cart" src={amount > 0 ? blueCart : cart} alt="cart" />
              </button>
              <button
                onClick={() => setShowSearch(!showSearch)}
                style={{ cursor: "pointer" }}
              >
                <img className="lupa-icon" src={lupa} alt="search" />
              </button>
            </div>
            <a className="logotype--mobile" href="/">
              <img src={logo} alt="Svizha" />
            </a>

            <button onClick={handleClick} className="burger">
              <img className="menu" src={burger} alt="menu" />
            </button>

            {showMenu && (
              <div className={`dropdown-menu-mobile`}>
                <div className="header--mobile--container">
                  <div className="dropdown--menu--top">
                    <button onClick={() => setCartVisible(true)}>
                      <img className="cart" src={cart} alt="cart" />
                    </button>

                    <a className="logotype--mobile" href="/">
                      <img src={logo} alt="Svizha" />
                    </a>

                    <button onClick={handleClick}>
                      <img src={closeMenu} alt="Закрити меню" />
                    </button>
                  </div>

                  <ul>
                    <li>
                      <a href="/menu">{t("header.menu")}</a>
                    </li>
                    <li>
                      <a href="about-us">{t("header.about_us")}</a>
                    </li>
                    <li>
                      <a href="buy-us">{t("header.where_to_buy")}</a>
                    </li>
                    <li>
                      <a href="for-partners">{t("header.for_partners")}</a>
                    </li>
                    <li>
                      <a href={accessToken ? "/logout" : "/login"}>
                        {accessToken ? t("header.logout") : t("header.login")}
                      </a>
                    </li>
                    <li>
                      <details className="language">
                        <summary>
                          {i18n.language === "ukr"
                            ? "УКР"
                            : i18n.language === "en"
                            ? "ENG"
                            : "DEU"}
                        </summary>
                        <div className="language_list">
                          <a href="#" onClick={() => changeLanguage("en")}>
                            <li
                              style={{
                                display:
                                  i18n.language === "en" ? "none" : "block",
                              }}
                            >
                              ENG
                            </li>
                          </a>
                          <a href="#" onClick={() => changeLanguage("deu")}>
                            <li
                              style={{
                                display:
                                  i18n.language === "deu" ? "none" : "block",
                              }}
                            >
                              DEU
                            </li>
                          </a>
                          <a href="#" onClick={() => changeLanguage("ukr")}>
                            <li
                              style={{
                                display:
                                  i18n.language === "ukr" ? "none" : "block",
                              }}
                            >
                              УКР
                            </li>
                          </a>
                        </div>
                      </details>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </header>
      )}
    </>
  );
}

export default MobileHeader;