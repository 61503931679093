import React from "react";
import "./FAQ.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const questions = [
  {
    id: 1,
    question: "FAQ.What.question",
    answer: "FAQ.What.answer",
  },
  {
    id: 2,
    question: "FAQ.Reheating.question",
    answer: "FAQ.Reheating.answer",
  },
  {
    id: 3,
    question: "FAQ.Refreezing.question",
    answer: "FAQ.Refreezing.answer",
  },
  {
    id: 4,
    question: "FAQ.ShelfLife.question",
    answer: "FAQ.ShelfLife.answer",
  },
  {
    id: 5,
    question: "FAQ.OrderDelivery.question",
    answer: "FAQ.OrderDelivery.answer",
  },
];

function Question(props) {
  const [expanded, setExpanded] = useState(false);
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
  const { t } = useTranslation();

  return (
    <div
      className={`accordion-item ${expanded ? "expanded" : ""}`}
      id={`q${props.id}`}
    >
      <div
        className={`accordion-item-header ${expanded ? "expanded" : ""}`}
        onClick={toggleAccordion}
      >
        {t(props.question)}
      </div>
      <div className="accordion-item-body">
        <div className="accordion-item-body-content">
          <p>{t(props.answer)}</p>
        </div>
      </div>
    </div>
  );
}

function FAQ() {
  return (
    <div className="accordion">
      {questions.map((question) => (
        <Question
          key={question.id}
          id={question.id}
          question={question.question}
          answer={question.answer}
        />
      ))}
    </div>
  );
}

export default FAQ;