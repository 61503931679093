import { useState } from "react";
import "./LoginForm.css";
import facebookicon from "../../images/facebookicon.png";
import googleicon from "../../images/googleicon.png";
import emailicon from "../../images/emailicon.svg";
import passwordicon from "../../images/passwordicon.svg";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../features/user/userSlice";
import { login } from "../../features/user/userSlice";
import hideicon from "../../images/hideicon.svg";
import hideiconblack from "../../images/hideiconblack.svg";
import { useTranslation } from "react-i18next";

export default function LoginForm() {
  const { t } = useTranslation();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [incorrectData, setIncorrectData] = useState(false);
  const dispatch = useDispatch();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      console.log(response);
      dispatch(googleLogin(response.access_token));
    },
    onError: (response) => console.log("Login Failed"),
  });

  const handleLogin = async (email, pass) => {
    const obj = {
      email: email,
      password: pass,
    };
    const response = await dispatch(login(obj));
    if (response.error) {
      setIncorrectData(true);
    }
  };

  return (
    <div className="order-login-form">
      <h4>{t("LoginForm.Sign_in")}</h4>

      <div className="socials-logins">
        <button className="fb">
          <img src={facebookicon} alt="" />
          <p>{t("LoginForm.Sign")}</p>
        </button>
        <button onClick={() => handleGoogleLogin()} className="gg">
          <img src={googleicon} alt="" />
          <p>{t("LoginForm.Google")}</p>
        </button>
      </div>

      <div className="abo-divider">
        <div></div>
        {t("LoginForm.or")}
        <div></div>
      </div>

      <form className="login-form order-login-form">
        <div className="input-login">
          <div className="input-top">
            <p>Email</p>
          </div>
          <div className="input-mid">
            <div className="input-mid-left">
              <img src={emailicon} alt="" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("LoginForm.Enter")}
              />
            </div>
          </div>
          <div className="input-bottom">
            <span className="blue-underline"></span>
          </div>
        </div>

        <div className="input-password">
          <div className="input-top">
            <p>{t("LoginForm.Password")}</p>
          </div>
          <div className="input-mid">
            <div className="input-mid-left">
              <img src={passwordicon} alt="" />
              <input
                type={visiblePassword ? "text" : "password"}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder={t("LoginForm.Enter_password")}
              />
            </div>
            <div className="input-mid-right">
              <button
                type="button"
                className="visible-password"
                onClick={() => setVisiblePassword(!visiblePassword)}
              >
                <img src={visiblePassword ? hideiconblack : hideicon} alt="" />
              </button>
            </div>
          </div>
          <div className="input-bottom">
            <span className="blue-underline"></span>
          </div>
        </div>

        <p
          style={incorrectData ? { display: "block" } : { display: "none" }}
          className="already-exists"
        >
          {t("LoginForm.Incorrect")}
        </p>

        <button
          type="button"
          className="login"
          onClick={() => handleLogin(email, pass)}
        >
          {t("LoginForm.Sign_in")}
        </button>

        <div className="notregistered">
          <p>{t("LoginForm.Dont")}</p>
          <a href="/register">{t("LoginForm.Registration")}</a>
        </div>
      </form>
    </div>
  );
}
