import "./TestProductPage.css";
import "swiper/css";
import "swiper/css/pagination";

import Suggestions from "../../Components/Suggestions/Suggestions";
import { useParams } from "react-router-dom";
import { useGetProductByIdQuery } from "../../app/store";
import { useDispatch } from "react-redux";
import { addToCart } from "../../features/shoppingCart/shoppingCartSlice";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import increaseImg from "../../images/increase.svg";
import reduce from "../../images/reduce.svg";
import arrowBack from "../../images/arrow-back.png";
import Loading from "../../Components/Loading/Loading";
import StarRating from "../../Components/StarRating/StarRating";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

function TestProductPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [amount, setAmount] = useState(1);
  const [activeButton, setActiveButton] = useState("desc");
  const [contentClass, setContentClass] = useState("desc--section");
  const [leaveComment, setLeaveComment] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [users, setUsers] = useState({});

  const navigate = useNavigate();

  let { productId } = useParams();
  const { data, error, isLoading } = useGetProductByIdQuery(productId);

  const handleAddToCart = (product) => {
    for (let i = 1; i <= amount; i++) {
      dispatch(addToCart(product));
    }
  };


  useEffect(() => {
    axios
      .get(`https://svizhabe.herokuapp.com/uk/reviews/${productId}/`)
      .then((response) => {
        setReviews(response.data);
        setCurrentReview(response.data[0]);
        const userIds = response.data.map((review) => review.user_id);
        const uniqueUserIds = Array.from(new Set(userIds));
        uniqueUserIds.forEach((userId) => {
          axios
            .get(`https://svizhabe.herokuapp.com/users/${userId}`)
            .then((response) => {
              setUsers((prevUsers) => ({
                ...prevUsers,
                [userId]: response.data.first_name,
              }));
            })
            .catch((error) => {
              console.error(error);
            });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [productId]);

  return (
    <main>
      <div className="product--page--container">
        <button onClick={() => navigate(-1)} className="zuruck">
          <div className="back">
            <img src={arrowBack} alt="" />
            <span>{t("CategoryProductsPage.CategoryProductsPage")}</span>
          </div>
          <h1 className="hide">{"categoryId"}</h1>
        </button>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <p>An error occured</p>
        ) : (
          <>
            <div className="product--info">
              <div className="product--photo">
                {data.test_slider_images.length === 0 ? (
                  <img className="product-photo-img" src={data.image_url} />
                ) : (
                  <Swiper
                    modules={[Pagination, Navigation]}
                    navigation={true}
                    pagination={{
                      type: "bullets",
                    }}
                    className="imageswiper"
                  >
                    <SwiperSlide>
                      <img
                        className="product-photo-img"
                        src={data.image_url}
                        alt=""
                      />
                    </SwiperSlide>
                    {data.test_slider_images.map((image_url) => (
                      <SwiperSlide>
                        <img
                          className="product-photo-img"
                          src={image_url}
                          alt=""
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>

              <div className="product--description">
                <div
                  style={
                    data.discount === null
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  className="discountel"
                >
                  <span>-{data.discount?.discount_percentage}%</span>
                </div>

                <h2>{data.title}</h2>
                <p>{data.ingredients}</p>
                <hr />
                <div className="weight--buttons">
                  <button>
                    <a href="/">
                      {data.grammage} {data.grammage === 1 ? "pc" : "g"}
                    </a>
                  </button>
                </div>
                <h1
                  style={
                    data.discount === null
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {data.price} {t("ProductPage.UAH")}
                </h1>

                <div
                  className="pricess"
                  style={
                    data.discount === null
                      ? { display: "none" }
                      : { display: "flex" }
                  }
                >
                  <h2>
                    {data.price} {t("ProductPage.UAH")}
                  </h2>
                  <h1>
                    {data.discounted_price} {t("ProductPage.UAH")}
                  </h1>
                </div>

                <div className="order--buttons">
                  <div className="count--buttons">
                    <button
                      className="amount-btn"
                      onClick={() => {
                        if (amount > 1) {
                          setAmount(amount - 1);
                        }
                      }}
                    >
                      <img src={reduce} alt="-" />
                    </button>
                    <h3>{amount}</h3>
                    <button
                      className="amount-btn"
                      onClick={() => {
                        setAmount(amount + 1);
                      }}
                    >
                      <img src={increaseImg} alt="+" />
                    </button>
                  </div>
                  <button
                    className="order--button"
                    onClick={() => handleAddToCart(data)}
                  >
                    {t("main.I_want_to_order")}
                  </button>
                </div>
              </div>
            </div>

            <div className="comments">
              <div className="instructions">
                <button
                  className={activeButton === "desc" ? "button--active" : ""}
                  onClick={() => {
                    setActiveButton("desc");
                    setContentClass("desc--section");
                  }}
                >
                  {t("ProductPage.Description")}
                </button>

                <button
                  className={activeButton === "reviews" ? "button--active" : ""}
                  onClick={() => {
                    setActiveButton("reviews");
                    setContentClass("comment--section");
                  }}
                >
                  {t("ProductPage.Reviews")}
                </button>

                <button
                  className={activeButton === "how-to" ? "button--active" : ""}
                  onClick={() => {
                    setActiveButton("how-to");
                    setContentClass("how_to_warm_up");
                  }}
                >
                  {t("ProductPage.How_to_warm_up")}
                </button>

                {/* <button
                  className={activeButton === "recipe" ? "button--active" : ""}
                  onClick={() => {
                    setActiveButton("recipe");
                    setContentClass("recipe--section");
                  }}
                >
                  Рецепт цього шедевру
                </button> */}

                <button
                  className={
                    activeButton === "calories" ? "button--active" : ""
                  }
                  onClick={() => {
                    setActiveButton("calories");
                    setContentClass("calories--section");
                  }}
                >
                  {t("ProductPage.Caloric_content")}
                </button>
              </div>

              <div
                style={
                  contentClass === "desc--section"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="desc--section"
              >
                <p style={{ whiteSpace: "pre-wrap" }}>{data.description}</p>
              </div>

              <div
                style={
                  contentClass === "comment--section"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="comment--section"
              >
                {reviews.length === 0 ? (
                  <p className="no-reviews">{t("ProductPage.Now")}</p>
                ) : (
                  <div
                    style={
                      leaveComment ? { display: "none" } : { display: "block" }
                    }
                  >
                    <div className="comment--section--top">
                      {currentReview && (
                        <div className="user--comment--info">
                          <h4>{users[currentReview.user_id]}</h4>
                          <div className="stars">
                            {Array.from({ length: currentReview.rating }).map(
                              (_, index) => (
                                <span
                                  key={index}
                                  className="fa fa-star fa-lg checked"
                                ></span>
                              )
                            )}
                            {Array.from({
                              length: 5 - currentReview.rating,
                            }).map((_, index) => (
                              <span
                                key={index}
                                className="fa fa-star fa-lg"
                              ></span>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="scroll">
                        <button
                          disabled={currentIndex === 0}
                          onClick={() => {
                            setCurrentIndex(currentIndex - 1);
                            setCurrentReview(reviews[currentIndex - 1]);
                          }}
                        >
                          {"<"}
                        </button>
                        <span>{`${currentIndex + 1}/${reviews.length}`}</span>
                        <button
                          disabled={currentIndex === reviews.length - 1}
                          onClick={() => {
                            setCurrentIndex(currentIndex + 1);
                            setCurrentReview(reviews[currentIndex + 1]);
                          }}
                        >
                          {">"}
                        </button>
                      </div>
                    </div>

                    <p className="comment--text">{currentReview?.comment}</p>
                  </div>
                )}

                <div
                  style={
                    leaveComment ? { display: "block" } : { display: "none" }
                  }
                  className="comment-input"
                >
                  <StarRating
                    product_id={productId}
                    onLeaveCommentChange={setLeaveComment}
                  />
                </div>

                <button
                  onClick={() => setLeaveComment(true)}
                  style={
                    leaveComment ? { display: "none" } : { display: "block" }
                  }
                  className="leave--comment"
                >
                  {t("StarRating.Leave")}
                </button>
              </div>

              <div
                style={
                  contentClass === "recipe--section"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="how--to--cook"
              >
                <p>{data.recipe}</p>
              </div>

              <div
                style={
                  contentClass === "how_to_warm_up"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="how_to_warm_up"
              >
                <p>{data.how_to_warm_up}</p>
              </div>

              <div
                style={
                  contentClass === "calories--section"
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="calories--section"
              >
                <p>{data.calories}</p>
              </div>
            </div>
          </>
        )}
        <Suggestions id={productId} />
      </div>
    </main>
  );
}

export default TestProductPage;
