import "../../Pages/OrderPage/OrderPage.css";
import lillogo from "../../images/lillogo.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function SuccessPage() {
  const { t } = useTranslation();
  return (
    <main>
      <div className="thanks-for-order">
        <div className="thanks-for-order-container">
          <img src={lillogo} alt="Ї" />
          <h1>{t("SuccessPage.Thank")}</h1>
          <p>
            {t("SuccessPage.Soon")}
          </p>
          <Link to={"/"}>
            <span>{t("SuccessPage.Continue")}</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
