import React from "react";
import styles from "./OurOffers.module.css";

import quality from "../../images/icons/quality.svg";
import time from "../../images/icons/time.svg";
import freeze from "../../images/icons/freeze.svg";
import eco from "../../images/icons/eco.svg";

import { useTranslation } from "react-i18next";

const OurOffers = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.offer}>
      <h2>{t("WhatDoWeOffer.heading")}</h2>

      <div className={styles.cards}>
        <div className={styles.card}>
          <img src={time} alt="time economy" />
          <p>
            <span>{t("WhatDoWeOffer.timeEconomy")}</span>
            {t("WhatDoWeOffer.timeEconomyText")}
          </p>
        </div>

        <div className={styles.card}>
          <img src={freeze} alt="freeze" />
          <p>
            <span>{t("WhatDoWeOffer.shockFreezing")}</span>
            {t("WhatDoWeOffer.shockFreezingText")}
          </p>
        </div>

        <div className={styles.card}>
          <img src={quality} alt="quality" />
          <p>
            <span>{t("WhatDoWeOffer.quality")}</span>
            {t("WhatDoWeOffer.qualityText")}
          </p>
        </div>

        <div className={styles.card}>
          <img src={eco} alt="Convenient and eco-friendly" />
          <p>
            <span>{t("WhatDoWeOffer.convenience")}</span>
            {t("WhatDoWeOffer.convenienceText")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurOffers;
