import "../ExchangePolicyPage/ExchangePolicyPage.css";
import { useTranslation } from "react-i18next";

export default function OfferContractPage() {
  const { t } = useTranslation();
  return (
    <div className="exchange-policy-container">
      <h3 className="modal-heading" style={{ "margin-bottom": "30px" }}>
        {t("AboutUS.Offer_contract")}
      </h3>

      <div className="article-text __fullWidth text">
        <p>
          {t("OfferContractPage.Public_offer_contract")}
          <br />
          {t("OfferContractPage.Under_this")}
        </p>

        <p>{t("OfferContractPage.Sellers_intending")}</p>

        <p>
          {t("OfferContractPage.TERMS")}
          <br />
          {t("OfferContractPage.Contractual")}
        </p>

        <p>{t("OfferContractPage.The_public")}</p>

        <p>{t("OfferContractPage.This_Agreement")}</p>

        <p>{t("OfferContractPage.To_regulate")}</p>

        <p>{t("OfferContractPage.Legal_address")}</p>

        <p>{t("OfferContractPage.A_natural")}</p>

        <p>
          {t("OfferContractPage.TERMS_AND_DEFINITIONS")}
          <br />
          {t("OfferContractPage.Public_offer_contracts")}
        </p>

        <p>{t("OfferContractPage.Accept")}</p>

        <p>{t("OfferContractPage.Goods")}</p>

        <p>{t("OfferContractPage.Purchaser")}</p>

        <p>{t("OfferContractPage.Seller")}</p>

        <p>{t("OfferContractPage.Order")}</p>

        <p>{t("OfferContractPage.Legislation")}</p>

        <p>{t("OfferContractPage.Significant_defect_of_the_Product")}</p>

        <p>
          {t("OfferContractPage.SCOPE_OF_THE_CONTRACT")}
          <br />
          {t("OfferContractPage.The_Seller")}
        </p>

        <p>{t("OfferContractPage.The_Seller_guarantees")}</p>

        <p>{t("OfferContractPage.The_Seller_and")}</p>

        <p>{t("OfferContractPage.The_seller_confirms")}</p>

        <p>
          {t("OfferContractPage.RIGHTS")}
          <br />
          {t("OfferContractPage.seller")}
        </p>

        <p>{t("OfferContractPage.fulfill_the_terms")}</p>

        <p>{t("OfferContractPage.fulfill_the_Buyer's")}</p>

        <p>{t("OfferContractPage.deliver_the_Goods")}</p>

        <p>{t("OfferContractPage.to_check")}</p>

        <p>{t("OfferContractPage.inform_the_buyer")}</p>

        <p>{t("OfferContractPage.The_seller")}</p>

        <p>{t("OfferContractPage.unilaterally_suspend")}</p>

        <p>{t("OfferContractPage.Sellers")}</p>

        <p>{t("OfferContractPage.A_business")}</p>

        <p>
          {t("OfferContractPage.RIGHTS_AND_OBLIGATIONS")}
          <br />
          {t("OfferContractPage.The_buyer_is_obliged")}
        </p>

        <p>{t("OfferContractPage.timely_pay")}</p>

        <p>{t("OfferContractPage.read_the_information")}</p>

        <p>{t("OfferContractPage.upon_receiving")}</p>

        <p>{t("OfferContractPage.The_buyer_has_the_right_to")}</p>

        <p>{t("OfferContractPage.place_an_order")}</p>

        <p>{t("OfferContractPage.require_the_Seller")}</p>

        <p>{t("OfferContractPage.to_inform_about")}</p>

        <p>
          {t("OfferContractPage.ORDER_PROCEDURE")}
          <br />
          {t("OfferContractPage.The_Buyer_places")}
        </p>

        <p>{t("OfferContractPage.The_term_of_formation")}</p>

        <p>
          {t("OfferContractPage.CONTRACT_PRICE_AND_PAYMENT_PROCEDURE")}
          <br />
          {t("OfferContractPage.The_price_of_each")}
        </p>

        <p>{t("OfferContractPage.The_cost_of_the_Order")}</p>

        <p>{t("OfferContractPage.The_Buyer_can_pay")}</p>

        <p>{t("OfferContractPage.by_means")}</p>

        <p>{t("OfferContractPage.cash_on_delivery")}</p>

        <p>
          {t("OfferContractPage.Credit_card")}
          <br />
          Visa
        </p>

        <p>Visa Electron</p>

        <p>Mastercard</p>

        <p>Mastercard Electronic</p>

        <p>Maestro</p>

        <p>{t("OfferContractPage.in_any_other_way")}</p>

        <p>{t("OfferContractPage.Note")}</p>

        <p>
          {t("OfferContractPage.TERMS_OF_DELIVERY")}
          <br />
          {t("OfferContractPage.The_Buyer")}
        </p>

        <p>{t("OfferContractPage.When_delivering")}</p>

        <p>{t("OfferContractPage.The_fact")}</p>

        <p>{t("OfferContractPage.In_case")}</p>

        <p>{t("OfferContractPage.The_Buyers")}</p>

        <p>
          {t("OfferContractPage.TERMS_OF_RETURN")}
          <br />
          {t("OfferContractPage.According_to")}
        </p>

        <p>{t("OfferContractPage.If_the_Buyer")}</p>

        <p>{t("OfferContractPage.In_the_event")}</p>

        <p>{t("OfferContractPage.The_parties")}</p>

        <p>
          {t("OfferContractPage.LIABILITY")}
          <br />
          {t("OfferContractPage.The_parties_are")}
        </p>

        <p>{t("OfferContractPage.In_the_event_of")}</p>

        <p>{t("OfferContractPage.All_disputes")}</p>

        <p>
          {t("OfferContractPage.FORCE_MAJEURE")}
          <br />
          {t("OfferContractPage.The_Parties")}
        </p>

        <p>{t("OfferContractPage.Force_majeure")}</p>

        <p>{t("OfferContractPage.The_Party")}</p>

        <p>{t("OfferContractPage.The_time")}</p>

        <p>{t("OfferContractPage.If_due")}</p>

        <p>{t("OfferContractPage.Despite")}</p>

        <p>
          {t("OfferContractPage.OTHER_TERMS")}
          <br />
          {t("OfferContractPage.The_information")}
        </p>

        <p>{t("OfferContractPage.By_accepting")}</p>

        <p>{t("OfferContractPage.For_the_purposes")}</p>

        <p>{t("OfferContractPage.The_buyer")}</p>

        <p>{t("OfferContractPage.In_case_of")}</p>

        <p>{t("OfferContractPage.The_Seller_is")}</p>
      </div>
    </div>
  );
}
