import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import loginVector from "../../images/loginVector.png";
import emailicon from "../../images/emailicon.svg";
import passwordicon from "../../images/passwordicon.svg";
import hideicon from "../../images/hideicon.svg";
import hideiconblack from "../../images/hideiconblack.svg";
import facebookicon from "../../images/facebookicon.png";
import googleicon from "../../images/googleicon.png";

import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user/userSlice";

import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../features/user/userSlice";

import { useTranslation } from "react-i18next";

function Login() {

  const { t } = useTranslation();

  const { accessToken } = useSelector((state) => state.user);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [incorrectData, setIncorrectData] = useState(false);

  useEffect(() => {
    if (accessToken) {
      navigate("/personal-account");
    }
  }, [accessToken, navigate]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      console.log(response);
      dispatch(googleLogin(response.access_token));
    },
    onError: (response) => console.log("Login Failed"),
  });

  const handleLogin = async (email, pass) => {
    const obj = {
      email: email,
      password: pass,
    };
    const response = await dispatch(login(obj));
    if (response.error) {
      setIncorrectData(true);
    }
  };

  return (
    <main>
      <div className="login-container">
        <div className="login-top">
          <h1 className="login-heading">{t("LoginForm.Sign_in")}</h1>
          <img className="login-vector" src={loginVector} alt="" />
        </div>

        <div className="login-main">
          <form className="login-form">
            <div className="input-login">
              <div className="input-top">
                <p>Email</p>
              </div>
              <div className="input-mid">
                <div className="input-mid-left">
                  <img src={emailicon} alt="" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("LoginForm.Enter")}
                  />
                </div>
              </div>
              <div className="input-bottom">
                <span className="blue-underline"></span>
              </div>
            </div>

            <div className="input-password">
              <div className="input-top">
                <p>{t("LoginForm.Password")}</p>
              </div>
              <div className="input-mid">
                <div className="input-mid-left">
                  <img src={passwordicon} alt="" />
                  <input
                    type={visiblePassword ? "text" : "password"}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    placeholder={t("LoginForm.Enter_password")}
                  />
                </div>
                <div className="input-mid-right">
                  <button
                    type="button"
                    className="visible-password"
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    <img
                      src={visiblePassword ? hideiconblack : hideicon}
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="input-bottom">
                <span className="blue-underline"></span>
              </div>
            </div>

            <p
              style={incorrectData ? { display: "block" } : { display: "none" }}
              className="already-exists"
            >
              {t("LoginForm.Incorrect")}
            </p>


            <button
              type="button"
              className="login"
              onClick={() => handleLogin(email, pass)}
            >
              {t("LoginForm.Sign_in")}
            </button>

            <div className="notregistered">
              <p>{t("LoginForm.Dont")}</p>
              <a href="/register">{t("LoginForm.Registration")}</a>
            </div>
          </form>

          <div className="login-oauth">
            <span className="mobile-delimiter"></span>
            <button className="facebook-auth">
              <a href="/">
                <img src={facebookicon} alt="" />
                {t("LoginForm.Sign")}
              </a>
            </button>
            <button onClick={() => handleGoogleLogin()} className="google-auth">
              <p>
                <img src={googleicon} alt="" />
                {t("LoginForm.Google")}
              </p>
            </button>
            <div className="mobile-divider">
              <span></span>
              <p>{t("LoginForm.or")}</p>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
