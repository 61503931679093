import { useDispatch } from "react-redux";
import {
  removeItem,
  increase,
  decrease,
  set,
} from "../../features/shoppingCart/shoppingCartSlice";
import increaseImg from "../../images/increase.svg";
import reduce from "../../images/reduce.svg";
import toTrash from "../../images/delete.svg";
import "./CartItem.css";
import { useTranslation } from "react-i18next";


function CartItem({ id, image_url, title, discounted_price, grammage, amount, is_special_offer }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="fullw">
      <div className="cart-product" key={id}>
        <div className="cart-product-info">
          <img src={image_url} alt={title} />
          <div className="cart-product-numbers">
            <div className="numbers-top">
              <h3>{title}</h3>
              <p>{grammage} {t("ProductCard.weight")}</p>
            </div>
            <span className="cart-product-price">{discounted_price*amount} {t("CartItem.price")}</span>
          </div>
        </div>
        <div className="product--actions">
          <div className="amount-buttons">
            <button
              className="amount-btn"
              onClick={() => {
                if (amount === 1) {
                  dispatch(removeItem(id));
                  return;
                }
                dispatch(decrease({ id }));
              }}
            >
              <img src={reduce} alt="-" />
            </button>

            <input
              type="number"
              min="1"
              max="100"
              step="1"
              value={amount}
              onChange={(e) => {
                const value = Math.min(
                  Math.max(parseInt(e.target.value), 1),
                  100
                );
                dispatch(set({ id, amount: value }));
              }}
            />

            <button
              className="amount-btn"
              onClick={() => {
                dispatch(increase({ id }));
              }}
            >
              <img src={increaseImg} alt="+" />
            </button>
          </div>

          <button className="btn remove-btn">
            <img
              src={toTrash}
              alt="вилучити"
              onClick={() => {
                dispatch(removeItem(id));
              }}
            />
          </button>
        </div>
      </div>

      <hr className="grey" />
    </div>
  );
}

export default CartItem;
