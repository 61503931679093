// translation.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnTranslation from './locales/ENG/translation.json';
import UaTranslation from './locales/UA/translation.json';
import DeTranslation from './locales/DEU/translation.json';

const savedLanguage = localStorage.getItem('language') || 'ukr';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: EnTranslation },
      ukr: { translation: UaTranslation },
      deu: { translation: DeTranslation },
    },
    lng: savedLanguage,
    fallbackLng: "ukr",
    interpolation: { escapeValue: false },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});


export default i18n;