import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StreetsInput.css";

export default function StreetsInput({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  register,
  errorMessage,
  setValue,
}) {
  const [streets, setStreets] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState("");

  useEffect(() => {
    const fetchStreets = async () => {
      try {
        const response = await axios.post(
          "https://api.novaposhta.ua/v2.0/json/searchSettlementStreets",
          {
            apiKey: "93e83c596c1c4ffc1451f0319439bf15",
            modelName: "Address",
            calledMethod: "searchSettlementStreets",
            methodProperties: {
              StreetName: selectedStreet,
              SettlementRef: "e718a680-4b33-11e4-ab6d-005056801329",
            },
          }
        );

        const streetNames = response.data.data.flatMap((curr) =>
          curr.Addresses.map((address) => address.Present)
        );

        setStreets(streetNames);
      } catch (error) {
        console.error(error);
      }
    };

    if (selectedStreet) {
      fetchStreets();
    }
  }, [selectedStreet]);

  const handleStreetChange = (e) => {
    setSelectedStreet(e.target.value);
  };

  const handleStreetClick = (street) => {
    setValue(`${id}`, street);
    setSelectedStreet(street);
  };

  return (
    <div className="streetsinput">
      <div className="input-group">
        <label className={errorMessage ? "redlabel" : ""} htmlFor={id}>
          {label}
        </label>
        <div className="streets-input-group-right input-group-right">
          <input
            value={selectedStreet}
            id={id}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            className={errorMessage ? "redform" : ""}
            {...register}
            onChange={handleStreetChange}
            setValue={setValue}
          />
          {streets.map((street, index) => (
            <li
              key={index}
              onClick={() => {
                handleStreetClick(street);
              }}
            >
              {street}
            </li>
          ))}
          <span className="error-message">{errorMessage}</span>
        </div>
      </div>
    </div>
  );
}
