import styles from "./AboutUsPage.module.css";
import stage1 from "../../images/stage1.jpg";
import stage2 from "../../images/stage2.jpg";
import stage3 from "../../images/stage3.jpg";
import stage4 from "../../images/stage4.jpg";
import stage5 from "../../images/stage5.jpg";
import menu from "../../images/menu.svg";
import cooking from "../../images/cooking.svg";
import personal from "../../images/personal.svg";
import products from "../../images/products.svg";
import transportation from "../../images/transportation.svg";
import qualitycontrol from "../../images/qualitycontrol.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import React from "react";

const AboutUsText = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <p style={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
      {t("AboutUS.idea")}
      <br />
      <br />
      <span className={`${styles.expand} ${expanded ? styles.expanded : ""}`}>
        {t("AboutUS.so")}
        <br />
        <br />
        {t("AboutUS.and")}
        <br />
        <br />
        {t("AboutUS.in")}
      </span>
    </p>
  );
};

function AboutUsPage() {
  const { t } = useTranslation();
  return (
    <div className={styles.page}>
      <div className={styles.block}>
        <h3>{t("AboutUS.Who_are_we")}</h3>
        <AboutUsText />

        <iframe
          width="590"
          height="414"
          src="https://www.youtube.com/embed/vv3MbvZptuA"
          title="Sviizha"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>

      <div className={styles.block}>
        <h3>{t("AboutUS.about_us")}</h3>

        <p>
          {t("AboutUS.we")}
        </p>
      </div>

      <div className={styles.block}>
        <h3>{t("AboutUS.how")}</h3>

        <div className={styles.stage}>
          <h4>{t("AboutUS.first")}</h4>
          <p>
            {t("AboutUS.Preparation")}
          </p>
          <img src={stage1} alt="Вибір сировини" />
        </div>

        <div className={styles.stage}>
          <h4>{t("AboutUS.second")}</h4>
          <p>
            {t("AboutUS.important")}
          </p>
          <img src={stage2} alt="2. Етап." />
        </div>

        <div className={styles.stage}>
          <h4>{t("AboutUS.third")}</h4>
          <p>
           {t("AboutUS.We_always")} :)
          </p>

          <img src={stage3} alt="Фасування" />
        </div>

        <div className={styles.stage}>
          <h4>{t("AboutUS.Fourth")}</h4>
          <p>
            {t("AboutUS.our")}
          </p>
          <img src={stage4} alt="Заморозка" />
        </div>

        <div className={styles.stage}>
          <h4>{t("AboutUS.Fifth")}</h4>
          <p>
           {t("AboutUS.Food")}
          </p>
          <img src={stage5} alt="куштування" />
        </div>
      </div>

      <div className={styles.block}>
        <h3>{t("AboutUS.Our_principles")}</h3>

        <div className={styles.icons}>
          <div className={styles.icon}>
            <img className="menu--vector" src={menu} alt="Меню" />
            <h5>{t("AboutUS.menu")}</h5>
            <p>
             {t("AboutUS.We_offer")}
            </p>
          </div>

          <div className={styles.icon}>
            <img className="cooking--vector" src={cooking} alt="Приготування" />
            <h5>{t("AboutUS.Preparations")}</h5>
            <p>{t("AboutUS.We_cook")}</p>
          </div>

          <div className={styles.icon}>
            <img className="personal--vector" src={personal} alt="Персонал" />
            <h5>{t("AboutUS.Personal")}</h5>
            <p>
              {t("AboutUS.Fresh")}
            </p>
          </div>

          <div className={styles.icon}>
            <img className="products--vector" src={products} alt="Продукти" />
            <h5>{t("AboutUS.products")}</h5>
            <p>
              {t("AboutUS.The_selection")}
            </p>
          </div>

          <div className={styles.icon}>
            <img
              className="transportation--vector"
              src={transportation}
              alt="Транспортування"
            />
            <h5>{t("AboutUS.When_transporting")}</h5>
            <p>
              {t("AboutUS.Svezha_transports")}
            </p>
          </div>

          <div className={styles.icon}>
            <img
              className="qualitycontrol--vector"
              src={qualitycontrol}
              alt="Контроль якості"
            />
            <h5>{t("AboutUS.Quality_control")}</h5>
            <p>
              {t("AboutUS.We_have")}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <h3>{t("AboutUS.Serious_blocks")}</h3>

        <div className={styles.links}>
          <Link to={"/user-agreement"}>{t("AboutUS.Privacy_Policy")}</Link>
          <Link to={"/exchange-policy"}>{t("AboutUS.Exchange")}</Link>
          <Link to={"/offer-contract"}>{t("AboutUS.Offer_contract")}</Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
