import forPartners from "../../images/forPartners.jpg";
import companies1 from "../../images/companies1.jpg";
import companies2 from "../../images/companies2.jpg";
import companies3 from "../../images/companies3.jpg";
import catering1 from "../../images/catering1.jpg";
import catering2 from "../../images/catering2.jpg";
import catering3 from "../../images/catering3.jpg";
import retailers1 from "../../images/retailers1.jpg";
import retailers2 from "../../images/retailers2.jpg";
import retailers3 from "../../images/retailers3.jpg";
import "./ForPartnersTest.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ForPartnersTest() {
  const { t } = useTranslation();
  return (
    <main>
      <div className="for--partners--container">
        <h1>{t("header.for_partners")}</h1>
        <div className="intro">
          <img src={forPartners} alt="Для партнерів" />

          <div className="intro--info">
            <h3 className="blue-light-text">
              {t("ForPartnersPage.We_provide")}
            </h3>
            <p className="intro--text--start">
              <span className="blue-light-text">
                {t("ForPartnersPage.Kindergartens")}
              </span>{" "}
              {t("ForPartnersPage.wherever_you_are")}
            </p>
            <h3 className="blue-light-text">{t("ForPartnersPage.higher")}</h3>
            <p>{t("ForPartnersPage.We_believe")}</p>
            <h3 className="blue-light-text">{t("ForPartnersPage.Our_menu")}</h3>
            <p>{t("ForPartnersPage.means")}</p>

            <div className="info--cta">
              <button>
                <a href="tel:380-68-978-21-85">
                  {t("ForPartnersPage.to_call")}
                </a>
              </button>
              <p>{t("ForPartnersPage.Phone_number")}</p>
            </div>
          </div>
        </div>

        <div className="info--blocks">
          <div className="info--block">
            <h2>{t("ForPartnersPage.Craft")}</h2>
            <div className="info--block--images">
              <img src={retailers1} alt="Ритейлерам" />
              <img src={retailers3} alt="Ритейлерам" />
            </div>
            <p>{t("ForPartnersPage.Expand")}</p>
          </div>

          <div className="info--block">
            <h2> {t("ForPartnersPage.For_offices")} </h2>
            <div className="info--block--images">
              <img src={companies1} alt="Офіси" />
              <img src={companies2} alt="Госпіталі" />
              <img src={companies3} alt="Коворкінги" />
            </div>
            <p>{t("ForPartnersPage.Offer_your_employees")}</p>
          </div>

          <div className="info--block">
            <h2>{t("ForPartnersPage.For_cafes")}</h2>
            <div className="info--block--images">
              <img src={catering1} alt="Кейтеринг" />
              <img src={catering2} alt="Кафе" />
              <img src={catering3} alt="Ресторани" />
            </div>
            <p>{t("ForPartnersPage.Simplify")}</p>
            <Link
              to={
                "https://drive.google.com/file/d/18cc6fc1xWPn9te4G4dUQmzFqOFblpl3n/view?usp=drive_link"
              }
            >
              {t("ForPartnersPage.Get_a_personal_offer")}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ForPartnersTest;
