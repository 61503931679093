import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./fonts/Formular/formular.css";
import "./fonts/RandomGrotesque/random-grotesque.css";
import "./style.css";
import store from "./app/store"
import { Provider } from 'react-redux'
import './i18n'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)


