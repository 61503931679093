import { Link, useNavigate } from "react-router-dom";
import "./ProductCard.css";
import { useDispatch } from "react-redux";
import { addToCart } from "../../features/shoppingCart/shoppingCartSlice";
import { useTranslation } from "react-i18next";

function ProductCard(props) {
  const dispatch = useDispatch();
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkClick = (id, event) => {
    event.preventDefault();
    window.scrollTo(0, 0); 
    navigate(`/products/${id}`);
  };

  return (
    <div className="product--card">
      <div
        style={props.bestseller ? { display: "block" } : { display: "none" }}
        className="xit"
      >
        <span>{t("ProductCard.HIT")}</span>
      </div>

      <div
        style={props.vegan ? { display: "block" } : { display: "none" }}
        className="vegan"
      >
        <span>{t("ProductCard.Vegan")}</span>
      </div>

      <div
        style={props.na_zakaz ? { display: "block" } : { display: "none" }}
        className="na-zakaz"
      >
        <span>Pre-order</span>
      </div>

      <div
        style={props.newdish ? { display: "block" } : { display: "none" }}
        className="top-seller"
      >
        <span>NEW</span>
      </div>

      <div
        style={
          props.is_special_offer ? { display: "block" } : { display: "none" }
        }
        className="special-offer"
      >
        <span>1+1=3</span>
      </div>

      <div
        style={
          props.discount === null ? { display: "none" } : { display: "block" }
        }
        className="discount"
      >
        <span>
          {props.discount?.discount_percentage < 10
            ? "SALE"
            : `-${props.discount?.discount_percentage}%`}
        </span>
      </div>

      <div
        style={props.meal ? { display: "block" } : { display: "none" }}
        className="sticker"
      >
        <span>{props.meal}</span>
      </div>

      <div onClick={(event) => handleLinkClick(props.id, event)}>
        <img src={props.image_url} alt={props.title} className="card--photo" />
      </div>
      <div className="card--description">
        <div className="info---section">
          <div
            className="card--name"
            onClick={(event) => handleLinkClick(props.id, event)}
          >
            {props.title}
          </div>
          <h4 className="card--weight">
            {props.grammage} {props.grammage === 1 ? "pc" : "g"}
          </h4>
        </div>

        <div className="buy-section">
          <button
            className="card--button"
            onClick={() => handleAddToCart(props)}
          >
           {t("ProductCard.basket")}
          </button>
          <h3
            style={
              props.discount === null
                ? { display: "block" }
                : { display: "none" }
            }
            className="card--price"
          >
            {props.price} {t("CartItem.price")}
          </h3>

          <div
            style={
              props.discount === null
                ? { display: "none" }
                : { display: "block" }
            }
            className="card--prices"
          >
            <h3 className="old-price">{props.price} {t("CartItem.price")}</h3>
            <h3 className="card--price">{props.discounted_price} {t("CartItem.price")}</h3>
          </div>
        </div>
      </div>

      <div className="card--description--mobile">
        <div className="card--info">
          <div
            className="card--name"
            onClick={(event) => handleLinkClick(props.id, event)}
          >
            {props.title}
          </div>
        </div>

        <div className="cart-bottom">
          <div className="price--and--weight">
            <h3
              style={
                props.discount === null
                  ? { display: "block" }
                  : { display: "none" }
              }
              className="card--price"
            >
              {props.price} {t("CartItem.price")}
            </h3>
            <div
              style={
                props.discount === null
                  ? { display: "none" }
                  : { display: "block" }
              }
              className="prices"
            >
              <h3 className="old-price">{props.price} {t("CartItem.price")}</h3>
              <h3 className="card--price">{props.discounted_price} {t("CartItem.price")}</h3>
            </div>
            <h4 className="card--weight">
              {props.grammage} {props.grammage === 1 ? "pc" : "g"}
            </h4>
          </div>

          <button
            className="card--button"
            onClick={() => handleAddToCart(props)}
          >
            {t("ProductCard.basket")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;