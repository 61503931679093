import React, { useEffect, useState } from "react";
import styles from "./Search.module.css";
import { useSearchProductsQuery } from "../../app/store";
import cross from "../../images/closeMenu.svg";

const Search = ({ close }) => {
  const [target, setTarget] = useState("");
  const { data, isLoading, error, refetch } = useSearchProductsQuery(target);

  useEffect(() => {
    console.log(data);
    refetch(target);
  }, [target]);

  const addTriple = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <>
      {/* Припускаємо, що у вас є хедер, розміщений перед цим компонентом */}
      <div className={styles.search}>
        <div className={styles.searchbox}>
          <input
            placeholder="Пошук продуктів..."
            value={target}
            onChange={(e) => setTarget(e.target.value)}
            className={styles.input}
            type="text"
          />
          <button onClick={close}>
            <img className={styles.cross} src={cross} alt="X" />
          </button>
        </div>
        <div className={styles.results}>
          {isLoading ? (
            <div>Loading...</div>
          ) : data && data.length > 0 ? (
            <ul>
              {data.map((product) => (
                <li key={product.id}>
                  <a href={`https://www.sviizha.com.ua/products/${product.id}`}>
                    <img src={product.image_url} alt={product.title} />
                    {addTriple(product.title, 30)}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;