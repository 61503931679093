import { useState, useRef, useEffect } from "react";
import styles from "./SelectInput.module.css";
import { useTranslation } from "react-i18next";

export default function SelectInput(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [opened, setOpened] = useState(false);
  const selectRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    if (opened) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [opened]);

  useEffect(() => {
    props.onChange(searchTerm);
  }, [searchTerm]);

  const handleInputChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = props.arr.filter((city) =>
      city.toLowerCase().includes(term)
    );

    setFilteredCities(filtered);
  };

  const handleCityClick = (city) => {
    setSearchTerm(city);
    setFilteredCities([]);
  };

  return (
    <div className={styles.select}>
      <label>{props.label}</label>

      <div ref={selectRef} className={styles.wrapper}>
        <input
          onClick={() => {
            setOpened(true);
          }}
          className={styles.header}
          placeholder={t("SelectInput.Enter")}
          value={searchTerm}
          onChange={handleInputChange}
        />
        <div className={`${styles.body} ${opened ? styles.active : ""}`}>
          {!searchTerm
            ? props.arr.map((el) => (
                <p key={el} onClick={() => handleCityClick(el, props.selectId)}>
                  {el}
                </p>
              ))
            : ""}
          {filteredCities &&
            filteredCities.map((el) => (
              <p key={el} onClick={() => handleCityClick(el, props.selectId)}>
                {el}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}
