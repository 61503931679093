import { configureStore } from "@reduxjs/toolkit";

import shoppingCartReducer from "../features/shoppingCart/shoppingCartSlice";
import { svizhaApi } from "../features/svizhaApi/svizhaApi";
import userReducer from "../features/user/userSlice";
import { refreshTheToken } from "../features/user/userSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    shoppingCart: shoppingCartReducer,
    [svizhaApi.reducerPath]: svizhaApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(svizhaApi.middleware),
});

setInterval(() => {
  if (localStorage.getItem("refreshToken")) {
    store.dispatch(refreshTheToken());
  }
}, 5 * 60 * 1000);

export const {
  useGetAllCategoriesQuery,
  useGetProductsByCategoryIdQuery,
  useGetProductByIdQuery,
  useGetMostPopularQuery,
  useGetProductsbyTagsQuery,
  useSearchProductsQuery,
} = svizhaApi;
export default store;
