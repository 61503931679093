import { useState } from "react";
import "./Select.css";

export default function Select(props) {
  const [selectedValue, setSelectedValue] = useState(props.arr[0]);

  const toggleSelect = (id) => {
    const selectId = document.querySelector(`#${id}`);
    const arrow = selectId.querySelector(".custom-select-arrows");
    const customSelectValues = selectId.querySelector(".custom-select-items");
    if (arrow && customSelectValues && selectId) {
      arrow.classList.toggle("activated");
      customSelectValues.classList.toggle("activated");
    }
  };

  const chooseValue = (val, id) => {
    setSelectedValue(val);
    if (props.onChange) {
      props.onChange(val);
    }
    const selectId = document.querySelector(`#${id}`);
    const arrow = selectId.querySelector(".custom-select-arrows");
    const customSelectValues = selectId.querySelector(".custom-select-items");
    if (arrow && customSelectValues && selectId) {
      arrow.classList.toggle("activated");
      customSelectValues.classList.toggle("activated");
    }
  };

  const selectItems = props.arr.map((el) => (
    <p key={el} onClick={() => chooseValue(el, props.selectId)}>
      {el}
    </p>
  ));

  return (
    <div className="forms--el" style={props.style}>
      <label>{props.label}</label>

      <div id={props.selectId} className="custom-select">
        <div
          className="custom-select-choice"
          onClick={() => toggleSelect(props.selectId)}
        >
          <p>{selectedValue}</p>
        </div>
        <div className="custom-select-items">{selectItems}</div>
        <div
          className="custom-select-arrows"
          onClick={() => toggleSelect(props.selectId)}
        >
          <div className="custom-select-arrow-wrapper">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
