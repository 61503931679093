import { useState } from "react";
import "./StarRating.css";
import axios from "axios";
import { selectUser } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function StarRating({ product_id, onLeaveCommentChange }) {
  const { t } = useTranslation();
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [comment, setComment] = useState("");
  const { accessToken, user_id } = useSelector(selectUser);
  const navigate = useNavigate();

  const handleSubmit = () => {
    const data = {
      rating: rating,
      comment: comment,
      product_id: product_id,
    };

    if (accessToken || user_id) {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios
        .post(
          `https://svizhabe.herokuapp.com/uk/products/${product_id}/reviews/create/`,
          data,
          {
            headers: headers,
          }
        )
        .then((response) => {
          onLeaveCommentChange(false);
          window.location.reload(); 
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="review-input">
      <div className="star-rating">
        {[...Array(5)].map((star, index) => {
          const currentRating = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={currentRating}
                onClick={() => setRating(currentRating)}
              />
              <span
                onMouseEnter={() => setHover(currentRating)}
                onMouseLeave={() => setHover(null)}
                style={
                  currentRating <= (hover || rating)
                    ? { color: "#50BDFF" }
                    : { color: "#CCCCCC" }
                }
                className="fa fa-star fa-lg"
              ></span>
            </label>
          );
        })}
      </div>

      <textarea
        placeholder={t("StarRating.Your")}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      ></textarea>

      <button onClick={handleSubmit}>{t("StarRating.Leave")}</button>
    </div>
  );
}
