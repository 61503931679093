// App.js
import "react-toastify/dist/ReactToastify.css";
import cross from "./images/cross.svg";
import toastifyCartIcon from "./images/toastify-cart-icon.svg";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateTotals } from "./features/shoppingCart/shoppingCartSlice";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import router from "./router";
import Header from "./Components/Header/Header";
import MobileHeader from "./Components/MobileHeader/MobileHeader";
import Footer from "./Components/Footer/Footer";
import MobileFooter from "./Components/MobileFooter/MobileFooter";
import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";

import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);
  const [cartsVisibility, setCartVisible] = useState(false);
  const { cartItems } = useSelector((store) => store.shoppingCart);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calculateTotals());
  }, [cartItems, dispatch]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1023);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <GoogleOAuthProvider clientId="640232162075-ok87vp3hlt30sihe796e39qvd1qtam0d.apps.googleusercontent.com">
      <React.StrictMode>
        <ToastContainer
          position={"bottom-right"}
          closeButton={<img alt="X" src={cross} />}
          icon={<img alt="🛒" src={toastifyCartIcon} />}
        />
        <ShoppingCart
          visibilty={cartsVisibility}
          onClose={() => setCartVisible(false)}
        />
        {isMobile ? (
          <MobileHeader setCartVisible={() => setCartVisible(true)} />
        ) : (
          <Header setCartVisible={() => setCartVisible(true)} />
        )}
        <RouterProvider router={router} />
        {isMobile ? <MobileFooter /> : <Footer />}
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}

export default App;
